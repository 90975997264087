import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link as ScrollLink } from "react-scroll"

import RightIcon from "../../../../assets/icons/chevron-right.svg"

class WhatItCostsDescription extends Component {
  state = {
    activeButton: "general",
  }

  onClickHandler = (button) => {
    this.setState({ activeButton: button })
  }

  generateNextButton = (label) => (
    <button className="btn btn-info" onClick={() => this.onClickHandler(label)}>
      <span className="pr-1 text-capitalize">Next Tab: {label} </span>
      <RightIcon />
    </button>
  )

  render() {
    let content, nextButton
    switch (this.state.activeButton) {
      case "general":
        content = (
          <>
            <p className="h2 fw-l">
              We <span className="text-primary">work with your budget</span> to
              deliver the ideal application.
            </p>
            <p className="text-muted">
              Each web application has unique feature, design, budget and time
              demands. Using an{" "}
              <a href="/approach/" target="_blank" className="btn-inline">
                Agile Approach
              </a>{" "}
              allows us to work with you to prioritize features and build the
              ideal application that fits your budget.
            </p>
            <p className="text-muted">
              We work with clients of all shapes and sizes so please don't
              hesitate to{" "}
              <ScrollLink
                to="contact"
                spy={true}
                smooth={true}
                duration={500}
                offset={-100}
              >
                <button className="btn btn-inline">Contact Us</button>
              </ScrollLink>
              . You may be pleasantly surprised as to how affordable your
              project can be.
            </p>
          </>
        )
        nextButton = this.generateNextButton("developers")
        break
      case "developers":
        content = (
          <>
            <p className="h2 fw-l">
              The size of your project determines the size of the development
              team.
            </p>
            <p className="text-muted">
              Larger projects with higher budgets and shorter timelines require
              more developers working as a team to deliver iterations on time.
              Conversely, we assign less developers to smaller projects with
              limited budgets and longer time frames.
            </p>
            <p className="text-muted">
              Our teams range from 1-10 developers with 10 being for very large
              projects spanning several months.
            </p>
          </>
        )
        nextButton = this.generateNextButton("budget")
        break
      case "budget":
        content = (
          <>
            <p className="h2 fw-l">
              We build web applications that{" "}
              <span className="text-primary">meet your budget</span> demands.
            </p>
            <p className="text-muted">
              Tactic Apps works on a Time-and-Materials billing system. This
              means that the price of a web application is largely dependent on
              the amount of work we need to deliver in the set amount of time.
              We charge anywhere between $65/hr to $150/hr depending on the
              factors we just mentioned.
            </p>
            <p className="text-muted">
              T&M is why we use{" "}
              <a href="/approach/" target="_blank" className="btn-inline">
                Agile Methodologies
              </a>{" "}
              to determine what the most important features are that fit your
              budget and ultimately provide your end users with the best
              experience possible. It also sets you up for the greatest chance
              of success.
            </p>
          </>
        )
        nextButton = this.generateNextButton("time")
        break
      case "time":
        content = (
          <>
            <p className="h2 fw-l">
              Your project plan includes{" "}
              <span className="text-primary">milestones</span> so we're always
              on the same page.
            </p>
            <p className="text-muted">
              We use{" "}
              <a href="/approach/" target="_blank" className="btn-inline">
                Agile Project Management
              </a>{" "}
              to deliver working features every 1-2 weeks. This iterative
              approach will give you a clear timeline for when specific features
              will be completed as well as an overall release date.
            </p>
            <p className="text-muted">
              Building well tested web applications take anywhere from 1-6
              Months for an initial release.
            </p>
          </>
        )
        nextButton = (
          <ScrollLink
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
          >
            <button className="btn btn-info">
              <span>Work With Us </span>
              <RightIcon />
            </button>
          </ScrollLink>
        )
        break
      default:
        content = null
        break
    }
    return (
      <StaticQuery
        query={graphql`
          {
            WhatItCostsDescription: file(
              relativePath: { eq: "time-budget.jpg" }
            ) {
              childImageSharp {
                gatsbyImageData(width: 900, layout: CONSTRAINED)
              }
            }
          }
        `}
        render={(data) => (
          <section id="who-we-are" className="my-5">
            <div className="container">
              <div className="row position-relative">
                <div className="square-bg" />
                <div className="col-md-6 mb-3">
                  <GatsbyImage
                    image={
                      data.WhatItCostsDescription.childImageSharp
                        .gatsbyImageData
                    }
                    alt="What it costs"
                    style={{
                      padding: "10px",
                      border: "10px solid rgba(255, 255, 255, 0.5)",
                      borderRadius: "5px",
                      boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <div className="story-buttons mb-1">
                    <button
                      className={
                        this.state.activeButton === "general"
                          ? "btn btn-link pl-0 active"
                          : "btn btn-link pl-0"
                      }
                      onClick={() => this.onClickHandler("general")}
                    >
                      <span>General</span>
                    </button>
                    <button
                      className={
                        this.state.activeButton === "developers"
                          ? "btn btn-link pl-0 active"
                          : "btn btn-link pl-0"
                      }
                      onClick={() => this.onClickHandler("developers")}
                    >
                      <span>Developers</span>
                    </button>
                    <button
                      className={
                        this.state.activeButton === "budget"
                          ? "btn btn-link active"
                          : "btn btn-link"
                      }
                      onClick={() => this.onClickHandler("budget")}
                    >
                      <span>Budget</span>
                    </button>
                    <button
                      className={
                        this.state.activeButton === "time"
                          ? "btn btn-link active"
                          : "btn btn-link"
                      }
                      onClick={() => this.onClickHandler("time")}
                    >
                      <span>Time</span>
                    </button>
                  </div>
                  {content}
                  <div>{nextButton}</div>
                </div>
              </div>
            </div>
          </section>
        )}
      />
    )
  }
}

export default WhatItCostsDescription
