import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const WebCaseStudies = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <StaticQuery
      query={graphql`
        {
          casestudy1: file(relativePath: { eq: "work/web/graphic-color.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          casestudy2: file(
            relativePath: { eq: "work/web/devlink-project.jpeg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          casestudy3: file(relativePath: { eq: "work/web/wfh-lister.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="WebCaseStudies" className="pt-5 my-5">
          <div className="container">
            <div className="row mb-4 pb-3">
              <div className="col-lg-9 m-auto text-center">
                <h2 className="text-capitalize">Case Studies</h2>
                <p className=" text-muted fw-l">
                  Take a look at some of our recent web development projects.
                </p>
              </div>
            </div>
          </div>
          <div className="container mb-5 pb-5 px-5">
            <Slider {...slickSettings}>
              <Link
                to="/work/web-applications/graphic-color"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy1.childImageSharp.gatsbyImageData}
                  alt="Web development case study 1"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading text-white">Graphic Color</h3>
                  <p className="description text-white text-center">
                    Digital sharing platform where users share photos, vectors,
                    illustrations, icons and more.
                  </p>
                </div>
              </Link>

              <Link
                to="/work/web-applications/devlink"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy2.childImageSharp.gatsbyImageData}
                  alt="Web development case study 2"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading text-white">Developer Link</h3>
                  <p className="description text-white text-center">
                    Free social network for developers where they can connect
                    with other developers and share their skills.
                  </p>
                </div>
              </Link>

              <Link
                to="/work/web-applications/wfh-lister"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy3.childImageSharp.gatsbyImageData}
                  alt="Web development case study 3"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading text-white">WFH Lister</h3>
                  <p className="description text-white text-center">
                    WFH Lister is a job listing application that focuses on the
                    work from home job market.
                  </p>
                </div>
              </Link>
            </Slider>
          </div>
        </section>
      )}
    />
  )
}

export default WebCaseStudies
