import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/common/PageHeader"
import WebDevelopment from "../../components/pages/services/web-development/WebDevelopment"
import WebAgileApproach from "../../components/pages/services/web-development/WebAgileApproach"
import WebWhatYouGet from "../../components/pages/services/web-development/WebWhatYouGet"
import WebWhatItCosts from "../../components/pages/services/web-development/WebWhatItCosts"
import WebTechnologies from "../../components/pages/services/web-development/WebTechnologies"
import WebCaseStudies from "../../components/pages/services/web-development/WebCaseStudies"
import Contact from "../../components/pages/index/Contact"

const WebDevelopmentPage = () => (
  <StaticQuery
    query={graphql`
      {
        webDevelopment: file(relativePath: { eq: "web-development.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Web Development"
          keywords={[`web development`]}
          description="Any browser, anywhere. We develop full-stack web applications that run on all devices with modern browsers. Your app will be built with clean, tested and scalable code."
        />
        <PageHeader
          image={data.webDevelopment.childImageSharp.gatsbyImageData}
          title="Web Development"
          subTitle="Device independent applications with unlimited potential"
          overlay="darker"
          formLocation="onPage"
        />
        <main>
          <WebDevelopment />
          <WebAgileApproach />
          <WebTechnologies />
          <WebWhatYouGet />
          <WebWhatItCosts />
          <WebCaseStudies />
          <Contact
            heading={
              <>
                Ready to build a custom{" "}
                <span className="text-primary">web</span> application?
              </>
            }
          />
        </main>
      </Layout>
    )}
  />
)

export default WebDevelopmentPage
