import React from "react"

import DeviceIcon from "../../../../assets/icons/phonelink.svg"
import InfinityIcon from "../../../../assets/icons/px.svg"
import MarketingIcon from "../../../../assets/icons/megaphone.svg"

const WebDevelopment = () => (
  <section id="WebDevelopment" className="my-5">
    <div className="container">
      <div className="row mb-4">
        <div className="col-lg-9 m-auto text-center">
          <h2 className="text-capitalize">Why build a web application?</h2>
          <p className=" text-muted fw-l">
            Web applications are dynamic websites with deep interactivity that
            provide users with a service. Here are 3 reasons why you should
            consider building one.
          </p>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-4 mb-5">
          <DeviceIcon className="pb-2" />
          <h5 className="text-uppercase">Device Independent</h5>
          <p className="text-muted fw-l">
            Whether you're using a desktop, laptop, iPhone, Android phone, or a
            tablet, your application will run on any device with a modern web
            browser.
          </p>
        </div>
        <div className="col-md-4 mb-5">
          <InfinityIcon className="pb-2" />
          <h5 className="text-uppercase">Unlimited Reach</h5>
          <p className="text-muted fw-l">
            While mobile applications are limited to users of the iOS or Android
            platforms, web applications reach users on all platforms essentially
            removing limitations.
          </p>
        </div>
        <div className="col-md-4">
          <MarketingIcon className="pb-2" />
          <h5 className="text-uppercase">Marketing Potential</h5>
          <p className="text-muted fw-l">
            Since web applications are just websites with deep interactivity,
            they're also easy to market through Seo and multiple different
            online and offline channels.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default WebDevelopment
